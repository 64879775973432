//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { productsWithPrices, assetURL } from '@/plugins/utilities'
import { mapGetters } from 'vuex'

const OrganizationSchema = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Rydale Clothing',
  logo: process.env.CLIENT_APP_URL + '/icon.png',
  image: process.env.CLIENT_APP_URL + '/icon.png',
  url: 'https://www.rydale.com',
  telephone: '01377 337160',
  vatID: 'GB 282 2562 58',
  address: {
    '@type': 'PostalAddress',
    addressLocality: 'Kelleythorpe Industrial Estate, Kelleythorpe',
    addressRegion: 'United Kingdom',
    postalCode: 'YO25 9FQ',
    streetAddress: 'Unit 76 Warfield Road',
  },
  sameAs: [
    'https://www.facebook.com/RydaleClothing?fref=ts',
    'https://www.pinterest.co.uk/rydaleclothing/',
    'https://www.rydale.com/blogs/news',
    'https://www.instagram.com/rydaleclothing/',
    'https://twitter.com/RydaleClothing',
  ],
}

export default {
  components: {
    BannerAlternative: () => import('@/containers/theme/BannerAlternative'),
    Banner: () => import('@/containers/theme/Banner'),
    CircleFeaturedCollectionsForUK: () =>
      import('@/containers/theme/CircleFeaturedCollectionsForUK'),
    CircleFeaturedCollections: () =>
      import('@/containers/theme/CircleFeaturedCollections'),
    ThemeSaleProduct: () => import('~/containers/theme/ThemeSaleProduct.vue'),
    InternationalThemeSaleProduct: () =>
      import('~/containers/theme/InternationalThemeSaleProduct.vue'),
    EmailSignup: () => import('@/components/EmailSignup.vue'),
    ExpandAndBlog: () => import('@/containers/theme/ExpandAndBlog.vue'),
    TypeSelection: () => import('@/containers/homepage/TypeSelection.vue'),
    TypeSlider: () => import('@/containers/homepage/TypeSlider.vue'),
    OurBestSeller: () => import('@/containers/theme/OurBestSeller.vue'),
    ReviewRating: () => import('@/containers/ReviewsRating.vue'),
  },

  async asyncData({ app, $axios, query, redirect }) {
    if (query.q) {
      redirect(`/products?q=${query.q}`)
    }

    try {
      const slugs = app.i18n.t('homePage.slugs')
      const pricesListTo = '/products/prices'
      return await $axios
        .$post(pricesListTo, {
          slugs,
        })
        .then((response) => {
          return {
            prices: response.data || [],
          }
        })
        .catch((error) => {
          console.error(error)
        })
    } catch (e) {
      console.log(e)
    }
  },
  data() {
    return {
      isDesktop: true,
      countdown: '0',
      intervalId: null,
      OrganizationSchema,
    }
  },
  head() {
    return {
      title: this.$t('homepage.seoTitle', {
        country:
          this.$i18n.locale.toUpperCase() === 'EN'
            ? 'UK'
            : this.$i18n.locale.startsWith('en-')
            ? this.$i18n.locale.split('-')[1].toUpperCase()
            : this.$i18n.locale.toUpperCase(),
      }),
      titleTemplate: '%s',
      meta: [
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.$t('homepage.seoTitle', {
            country:
              this.$i18n.locale.toUpperCase() === 'EN'
                ? 'UK'
                : this.$i18n.locale.startsWith('en-')
                ? this.$i18n.locale.split('-')[1].toUpperCase()
                : this.$i18n.locale.toUpperCase(),
          }),
        },
        {
          hid: 'description',
          name: 'description',
          content: this.$t('homepage.seoDescription'),
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.$t('homepage.seoDescription'),
        },
      ],
    }
  },
  computed: {
    ...mapGetters('ui', ['darkMode']),
    shownInUK() {
      return this.$i18n.locale === 'en'
    },
    shownInNonUK() {
      return this.$i18n.locale !== 'en'
    },
    themeSaleProduct() {
      if (this.prices) {
        return productsWithPrices(
          this.$t('homePage.ThemeSaleProduct'),
          this.prices
        )
      } else {
        return this.$t('homePage.ThemeSaleProduct')
      }
    },
    themeSaleProduct2() {
      if (this.prices) {
        return productsWithPrices(
          this.$t('homePage.ThemeSaleProduct2'),
          this.prices
        )
      } else {
        return this.$t('homePage.ThemeSaleProduct2')
      }
    },
    themeSaleProduct3() {
      if (this.prices) {
        return productsWithPrices(
          this.$t('homePage.ThemeSaleProduct3'),
          this.prices
        )
      } else {
        return this.$t('homePage.ThemeSaleProduct3')
      }
    },
    StackedProducts1() {
      return this.$t('homePage.stackedProducts1')
    },
    StackedProducts2() {
      return this.$t('homePage.stackedProducts2')
    },
    StackedProducts3() {
      return this.$t('homePage.stackedProducts3')
    },
  },
  beforeMount() {
    this.isDesktop = window.innerWidth > 499
  },
  mounted() {
    this.countdownToNewYear()
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  },
  methods: {
    assetURL,
    countdownToNewYear() {
      const newYear = new Date('25 December 2024')

      this.updateCountdown(newYear)

      this.intervalId = setInterval(() => {
        this.updateCountdown(newYear)
      }, 60000)
    },
    updateCountdown(newYear) {
      const now = new Date()
      const timeDifference = newYear - now

      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      )

      if (days > 1) {
        this.countdown = `${days} DAYS`
      } else {
        this.countdown = `${hours} HOURS ${minutes} MINUTES`
      }
    },
  },
}
