import { render, staticRenderFns } from "./index.vue?vue&type=template&id=89d3ee86&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=89d3ee86&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89d3ee86",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EmailSignup: require('/app/components/EmailSignup.vue').default,ThemeImage: require('/app/components/ThemeImage.vue').default})
